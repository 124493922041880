import * as types from "../types";

const initialState = {
  loading: false,
  requestUser: null,
  userData: null,
  error: null,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        requestUser: action.payload.requestUser,
      };

    case types.LOGIN_SUCCESS:
      sessionStorage.setItem("refresh_token",action.payload.userData.data.auth_tokens.refresh_token)
      return {
        ...state,
        loading: false,
        userData: action.payload.userData,
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        requestUser: null,
        userData: null,
        error: action.payload.error,
      };

      case types.LOGIN_RESET:
        sessionStorage.removeItem('refresh_token')
        return {
          ...state,
          loading: false,
          requestUser: null,
          userData: null,
          error: null,
        };

    default:
      return state;
  }
}
