import axios from "axios";
import { store } from '../store'
import { loginReset } from '../store/action/loginAction';

export const getUser = async ({ requestUser }) => {
    try {
      const res = await axios.post(
        `https://uat.carnot-traclytics.com/fleet/analytics_login/`,
        { username:requestUser.username, password: requestUser.password }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  export const getRefreshAccessToken = async () => {
    try {
        const { data } = await axios({
            method: 'post',
            url: `https://uat.carnot-traclytics.com/users/refresh_access_token/`,
            headers: {
                'User-Auth': `Bearer ${sessionStorage.getItem(
                    'refresh_token'
                )}`,
                'app-build': 'canlytics',
                'app-version': '1.0.1',
                platform: 'web',
            },
        })
    } catch (error) {
        store.dispatch(loginReset())
        throw error
    }
}